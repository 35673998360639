.header {
  height: auto;
  width: 100px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  .fixed-container {
    position: fixed;
  }

  .btn-and-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo-container {
    height: 100%;
    width: auto;
    padding: 18px;
  }

  .options {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    .option {
      padding: 10px 15px;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 768px) {
  .header {
    flex-direction: row;
    height: 70px;
    align-items: unset;
    margin-top: 0;
    width: auto;

    .fixed-container {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .btn-and-logo-container {
      flex-direction: row;
    }

    .logo-container {
      width: 70px;
    }

    .options {
      width: 50%;
      flex-direction: row;
    }
  }
}
