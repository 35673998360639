.cart-dropdown {
  position: fixed;
  width: 240px;
  height: 340px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  background-color: white;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  .empty-message {
    font-size: 18px;
    margin: 50px auto;
  }

  .cart-items {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  button {
    margin-top: auto;
  }
}

@media only screen and (min-width: 768px) {
  .cart-dropdown {
    position: absolute;
    top: 90px;
    right: 10px;
    left: auto;
    transform: translateX(0);
  }
}
