body {
  font-family: 'Open Sans Condensed';
  padding: 20px 0;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}

.main-wrapper {
  display: flex;
}

@media only screen and (min-width: 768px) {
  body {
    padding: 20px 60px;
  }
  .main-wrapper {
    flex-direction: column;
  }
}
