.collection-page {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 38px;
    margin: 0 auto 30px;
  }

  .items {
    display: flex;
    flex-direction: column;

    & .collection-item {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .collection-page {
    .items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 10px;
    }
  }
}
