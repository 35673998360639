.sign-in-and-sign-up {
  width: 850px;
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
@media only screen and (min-width: 760px) {
  .sign-in-and-sign-up {
    margin: 30px auto;
  }
}
@media only screen and (min-width: 960px) {
  .sign-in-and-sign-up {
    flex-direction: row;
  }
}
