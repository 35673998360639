.checkout-item {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;

  .image-and-name-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;

    .image-container {
      width: 90%;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      display: flex;
      justify-content: center;
      flex: 1;
    }
  }

  .quantity,
  .price {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  .quantity {
    display: flex;

    .arrow {
      cursor: pointer;
    }

    .value {
      margin: 0 6px;
    }
  }

  .remove-button {
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
  }
}

