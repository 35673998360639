.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  flex: 1;
}

@media only screen and (min-width: 768px) {
  .homepage {
    padding: 20px 80px;
  }
}
