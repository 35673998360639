.back-home-btn {
  background-color: #4986e7;
  border-radius: 4px;
  width: 50px;
  height: 45px;
  margin: 14px auto;
}
.back-home-btn:hover {
  opacity: 0.7;
}
.back-home-btn a {
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  padding: 0 12px;
}

@media only screen and (min-width: 768px) {
  .back-home-btn {
    margin: 0;
  }
}
