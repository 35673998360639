.collection-preview {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .title {
    font-size: 28px;
    margin-bottom: 25px;
  }

  .preview {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  .collection-preview {
    
    .preview {
      flex-direction: row;
    }
  }

}